<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <h3 class="tw-mb-0 tw-font-semibold tw-text-gray-700">
      {{ $t('Projects') }}
    </h3>
    <os-loader v-if="isLoadingProjects" class="mt-2" />
    <div
      v-else
      class="tw-grid xl:tw-grid-cols-4 tw-gap-6 lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-row-start-auto overflow-auto board-scroll tw-py-6"
    >
      <div
        v-for="(project, index) in $store.state.osBoard.projects"
        :key="index"
        class="hover-card tw-group tw-py-6 tw-px-8 tw-rounded-2xl tw-border tw-relative tw-cursor-pointer"
        @click="openProject(project)"
      >
        <div class="tw-text-center tw-flex tw-flex-col tw-h-full">
          <b-dropdown
            class="tw-hidden group-hover:tw-block chart-dropdown position-absolute zindex-4"
            no-caret
            right
            style="top: 0; right: -5px; margin: 1.5rem"
            toggle-class="p-0"
            variant="link"
          >
            <template #button-content>
              <feather-icon class="text-body cursor-pointer" icon="MoreVerticalIcon" size="18" />
            </template>
            <b-dropdown-item @click.prevent.stop="editProject(project.id)">
              {{ $t('Edit') }}
            </b-dropdown-item>
            <b-dropdown-item @click.prevent.stop="removeProject(project.id)">
              {{ $t('Remove') }}
            </b-dropdown-item>
          </b-dropdown>
          <h2 class="tw-text-2xl tw-font-semibold tw-cursor-pointer tw-text-gray-700">
            {{ project.title }}
          </h2>
          <p class="tw-text-center tw-text-sm tw-text-gray-600">
            {{ project.description }}
          </p>
          <div class="tw-mt-6 tw-flex-grow tw-flex tw-flex-col tw-justify-end">
            <b-avatar-group class="d-flex justify-content-center" size="32px">
              <b-avatar
                v-for="(member, index) in project.members.slice(0, 5)"
                :key="index"
                v-tippy="member.title"
                :src="member.avatar"
                :text="member.avatar === '' ? formatFullName(member.title) : null"
                class="pull-up tw-bg-gray-200 tw-min-w-[32px]"
              />
              <b-avatar
                v-if="project.members.length > 5"
                :text="`+${project.members.length - 5}`"
                class="pull-up tw-bg-gray-200 tw-text-gray-500/70"
              />
            </b-avatar-group>
            <p class="tw-m-0 tw-mt-4 tw-text-gray-500/90 tw-text-xs">
              {{ $t('Created') }} {{ project.creator.title }} (<span v-tippy="project.created_at">{{
                formatDate(project.created_at)
              }}</span
              >)
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="!showInputsForProject"
        class="tw-border tw-border-gray-100 hover:tw-border-gray-300 tw-transition tw-bg-white tw-p-6 tw-rounded-2xl tw-flex tw-items-center tw-justify-center tw-flex-col cursor-pointer"
        @click="showProject"
      >
        <p class="text-muted m-0 text-center text-primary-hover p-2 d-flex align-items-center justify-content-center">
          <feather-icon :icon="'PlusIcon'" class="mr-25 font-medium-1" />
          {{ $t('New project') }}
        </p>
      </div>
      <div
        v-else
        class="tw-border tw-border-gray-100 hover:tw-border-gray-300 tw-transition tw-bg-white tw-p-6 tw-rounded-2xl tw-flex tw-items-center tw-justify-center tw-flex-col"
      >
        <b-form-input
          v-model="newProject.title"
          :placeholder="$t('Name project')"
          class="w-100"
          @keydown.enter="createProject"
        ></b-form-input>
        <b-form-input
          v-model="newProject.description"
          :placeholder="$t('Description')"
          class="w-100 mt-1"
          @keydown.enter="createProject"
        ></b-form-input>
        <b-form-checkbox class="custom-control-primary mt-1 mb-0" name="check-button" switch>
          Internal project
        </b-form-checkbox>
        <b-dropdown
          v-if="options.length > 0"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :text="$t('Add member')"
          class="mt-50"
          no-caret
          size="sm"
          variant="primary"
        >
          <b-dropdown-item v-for="(member, index) in options" :key="index" @click="onMember(index)"
            >{{ member.title }}
          </b-dropdown-item>
        </b-dropdown>
        <b-avatar-group v-if="optionsForProject.length > 0" class="mt-1" size="32px">
          <b-avatar
            v-for="(choice_member, index) in optionsForProject"
            :key="index"
            v-b-tooltip.hover
            :src="choice_member.avatar"
            :text="choice_member.avatar === '' ? formatFullName(choice_member.title) : null"
            :title="choice_member.title"
            :variant="$store.state.other.colors[choice_member.id % 10]"
            class="pull-up tw-bg-gray-200 tw-min-w-[32px]"
            @click="onDeleteMember(index)"
          />
        </b-avatar-group>
        <div v-if="showInputsForProject" class="mt-1 d-flex">
          <button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="text-primary m-0 cursor-pointer tw-border tw-rounded-lg tw-px-3 tw-py-1.5"
            @click="createProject"
          >
            {{ $t('Create') }}
          </button>
          <button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="text-primary m-0 ml-1 cursor-pointer tw-border tw-rounded-lg tw-px-3 tw-py-1.5"
            @click="clearNewProject"
          >
            {{ $t('Cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BAvatar, BAvatarGroup, BDropdown, BDropdownItem, BFormCheckbox, BFormInput, VBTooltip} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import moment from 'moment';
import ProjectsService from '@/services/projects.service';
import UserService from '@/services/user.service';
import OsLoader from '@/components/OsLoader.vue';

export default {
  components: {
    OsLoader,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BAvatarGroup,
    BAvatar,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selected: null,
      colors: ['success', 'primary', 'secondary', 'danger', 'warning'],
      optionsForProject: [],
      options: [],
      newProject: {
        title: '',
        description: '',
      },
      showInputsForProject: false,
      isLoadingProjects: false,
    };
  },
  async mounted() {
    this.isLoadingProjects = true;
    await ProjectsService.getProjects();
    this.isLoadingProjects = false;
    UserService.getUsers().then(r => {
      if (r.status === 200) {
        this.$store.commit('osBoard/GET_USERS', r.data);
        this.$store.commit('osBoard/PRELOADER', false);
      }
      r.data.forEach(item => {
        if (item.id !== this.$store.state.osBoard.user.id) this.options.push(item);
      });
    });
  },
  methods: {
    editProject(id) {
      this.$store.commit('projects/SHOW_MODAL', id);
    },
    showProject() {
      this.showInputsForProject = true;
    },
    clearNewProject() {
      this.newProject.title = '';
      this.newProject.description = '';
      this.optionsForProject = [];
      this.showInputsForProject = false;
    },
    onMember(id) {
      this.optionsForProject.push(this.options[id]);
      this.options.splice(id, 1);
    },
    onDeleteMember(index) {
      console.log(index)
      this.optionsForProject.splice(index, 1);
    },
    createProject() {
      ProjectsService.createProject({
        title: this.newProject.title,
        description: this.newProject.description,
        is_internal: this.newProject.is_internal,
      }).then(response => {
        ProjectsService.getProjects();
        this.addMembers(response.data.id);
        this.clearNewProject();
      });
    },
    addMembers(id) {
      ProjectsService.addMembers(
        id,
        this.optionsForProject.map(el => {
          return {
            id: el.id,
          };
        })
      ).then(() => {
        ProjectsService.getProjects();
      });
    },
    removeProject(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          if (value) {
            ProjectsService.deleteProject(id).then(() => {
              this.clearNewProject();
            });
          }
        });
    },
    formatDate(date) {
      moment.locale(this.$i18n.locale);
      return moment(date, 'YYYY-MM-DD HH:mm:ss').fromNow();
    },
    formatFullName(name) {
      return name.split(' ')[0][0] + name.split(' ')[1][0];
    },
    openProject(project) {
      this.$store.commit('osBoard/SET_PROJECT', project);
      this.$router.push({name: 'project', query: {id: project.id}});
    },
    // serviceWorker() {
    //   if (!('serviceWorker' in navigator)) {
    //     console_logger('Браузер не поддерживает сервис-воркеры.')
    //   } else {
    //     console_logger('Браузер поддерживает сервис-воркеры.')
    //     window.addEventListener('load', function () {
    //       navigator.serviceWorker.register('/sw.js').then(
    //         function () {
    //           // Успешная регистрация
    //           console_logger('Сервис-воркер успешно зарегистрирован')
    //         },
    //         function (err) {
    //           // При регистрации произошла ошибка
    //           console_logger(`Ошибка регистрации сервис-воркера: ${err}`, 'error')
    //         }
    //       )
    //     })
    //   }
    //
    //   if (!('PushManager' in window)) {
    //     console_logger('Браузер не поддерживает push-уведомления.')
    //   } else {
    //     console_logger('Браузер поддерживает push-уведомления.')
    //   }
    // },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

//.projects .card:hover {
//    transform: translateY(-5px);
//    box-shadow: rgb(34 41 47 / 25%) 0px 4px 25px 0px;
//}
</style>